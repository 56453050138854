import { NavLink } from "react-router-dom";
import { useEffect, useState, useContext } from "react";
import { FaInstagram } from "react-icons/fa";
import { UserContext } from "./UserContext";
import { RxHamburgerMenu } from "react-icons/rx";
import { IoClose } from "react-icons/io5";

export default function Header() {
  const { setUserInfo } = useContext(UserContext);
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const apiUrl = process.env.REACT_APP_API_URL;

  useEffect(() => {
    fetch(`${apiUrl}/profile`, {
      credentials: "include",
    }).then((response) => {
      response.json().then((userInfo) => {
        setUserInfo(userInfo);
      });
    });
  }, []);

  const toggleMenu = () => {
    setIsMenuOpen((prev) => !prev);
  };

  return (
    <header className="w-full max-w-[1440px] flex items-center justify-between text-sm px-5 py-5 md:py-3 fixed z-10 bg-white/80 backdrop-blur-sm rounded-lg border-b border-b-gray-200 transition-all duration-300 ease-in-out">
      <NavLink className="header-wrapper-logo" to={"/"}>
        <img
          className="max-w-[80px] block"
          src="https://res.cloudinary.com/dipjef19s/image/upload/v1729065312/deskon_logo.png"
          alt=""
        />
      </NavLink>

      <div
        className="flex cursor-pointer text-base font-black md:hidden"
        onClick={toggleMenu}
      >
        {isMenuOpen ? <IoClose /> : <RxHamburgerMenu />}{" "}
      </div>

      <nav className={`header-wrapper-nav ${isMenuOpen ? "active" : ""}`}>
        <NavLink
          to={"/"}
          exact="true"
          onClick={toggleMenu}
          className={({ isActive }) => (isActive ? "active" : "")} // Conditionally apply 'active' class
        >
          Spaces
        </NavLink>
        <NavLink
          to={"/products"}
          onClick={toggleMenu}
          className={({ isActive }) => (isActive ? "active" : "")}
        >
          Products
        </NavLink>
        <NavLink
          to={"/community"}
          onClick={toggleMenu}
          className={({ isActive }) => (isActive ? "active" : "")}
        >
          Community
        </NavLink>
        <NavLink
          to={"/submit"}
          onClick={toggleMenu}
          className={({ isActive }) => (isActive ? "active" : "")}
        >
          Submit your space
        </NavLink>
        <div className="w-full m-auto md:hidden">
          <a
            className="flex gap-2 w-full m-auto items-center justify-center"
            href="https://www.instagram.com/deskon.hub/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <FaInstagram /> <span>Follow us!</span>
          </a>
        </div>
      </nav>

      <div className="hidden md:block">
        <a
          className="flex gap-2 items-center"
          href="https://www.instagram.com/deskon.hub/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <FaInstagram /> <span>Follow us!</span>
        </a>
      </div>
    </header>
  );
}
