import { useEffect, useState, useContext } from "react";
import { useParams, Link, useLocation } from "react-router-dom";
import { format } from "date-fns";
import { UserContext } from "../UserContext";
import { IoIosArrowBack } from "react-icons/io";
import { FiEdit } from "react-icons/fi";
import { LoadingSpinner } from "../components/LoadingSpinner";
import RelatedPosts from "./../components/RelatedPosts";
import { Helmet } from "react-helmet-async";
import { SpeedInsights } from "@vercel/speed-insights/react";

export default function PostPage() {
  const [postInfo, setPostInfo] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [enlargePic, setEnlaragePic] = useState(false);
  const { userInfo } = useContext(UserContext);
  const { id } = useParams();
  const apiUrl = process.env.REACT_APP_API_URL;

  const location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  useEffect(() => {
    const controller = new AbortController();
    const fetchPost = async () => {
      setLoading(true);
      try {
        const response = await fetch(`${apiUrl}/post/${id}`, {
          signal: controller.signal,
        });
        if (!response.ok) throw new Error("Failed to fetch post data");
        const data = await response.json();

        setPostInfo(data);
      } catch (error) {
        if (error.name === "AbortError") return; // Fetch was canceled
        setError(error.message);
      } finally {
        setLoading(false);
      }
    };

    fetchPost();

    // Clean up by aborting fetch on component unmount
    return () => controller.abort();
  }, [id, apiUrl]);

  if (loading) {
    return (
      <div className="page-padding loader">
        <div className="loading-spinner"></div>
        <p>Loading post...</p>
      </div>
    );
  }

  if (error) {
    return <p className="mt-20 text-base"> Kindly reload once again!</p>;
  }

  if (!postInfo) {
    return <div className="no-data-message">Post not found.</div>;
  }

  const isAuthor = userInfo?.id === postInfo.author._id;

  if (enlargePic) {
    return (
      <div className="fixed inset-0 bg-black/90 text-white min-h-screen z-20">
        <div className="max-w-[600px] m-auto p-8 min-h-screen flex flex-col items-start justify-center">
          <button
            onClick={() => setEnlaragePic(false)}
            className="text-sm flex gap-1 py-2 px-4 mb-8 rounded-2xl bg-white text-black shadow-md"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="size-5 "
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M6 18 18 6M6 6l12 12"
              />
            </svg>
            Close
          </button>
          <div className="h-full flex items-center justify-center ">
            <img
              className="rounded-lg w-[600px] max-w-full"
              src={postInfo.taggedcover}
              alt={`${postInfo.name}'s workspace`}
              loading="lazy"
              onError={(e) => (e.target.src = "/default-image.jpg")}
            />
          </div>
        </div>
      </div>
    );
  }

  return (
    <>
      <SpeedInsights />
      <Helmet>
        <title>DeskOn - Explore Tagged Workspaces</title>
        <meta
          name="description"
          content="Discover and shop your dream desk setup with DeskOn. Explore tagged workspaces, inspiring designs, and product recommendations."
        />
      </Helmet>
      <main className="postpage w-full py-[80px] pt-24 md:pt-32">
        <section className="postpage-container grid grid-cols-1 gap-5 max-w-[660px] lg:max-w-full">
          <header className="postpage-header flex justify-between items-end gap-3">
            <div className="postpage-header-left">
              <Link to="/" className="nav-back">
                <IoIosArrowBack aria-hidden="true" /> <span>Back to home</span>
              </Link>
              <p className="header-serial text-4xl">
                <span>Post </span>#{postInfo.serial}
              </p>
              <p className="header-author text-sm md:text-base">
                <span>Posted by: </span>
                {postInfo.author.username}
              </p>
              <p className="header-date text-sm md:text-base">
                <span>Created at: </span>
                {format(new Date(postInfo.createdAt), "MMM d, yyyy")}
              </p>
            </div>

            {isAuthor && (
              <div className="postpage-header-right">
                <Link className="edit-btn" to={`/edit/${postInfo._id}`}>
                  <FiEdit aria-hidden="true" /> <p>Edit post</p>
                </Link>
              </div>
            )}
          </header>

          <article className="user-post grid grid-cols-1 lg:grid-cols-2 gap-5 transition-all">
            <figure className="postpage-img w-[100%] ">
              <figcaption className="postpage-img-title text-xl font-medium pb-1">
                {postInfo.name}'s Workspace
              </figcaption>
              <img
                className="rounded-lg cursor-pointer"
                onClick={() => setEnlaragePic(true)}
                src={postInfo.taggedcover}
                alt={`${postInfo.name}'s workspace`}
                loading="lazy"
                onError={(e) => (e.target.src = "/default-image.jpg")}
              />
              <p className="postpage-cat">
                <span>Category: </span>
                <p className="inline-block mt-1 py-0.5 px-2 bg-[#eeeeee] rounded-md text-sm">
                  #{postInfo.category}
                </p>
              </p>
            </figure>

            <div className="postpage-info grid grid-cols-1 h-fit pt-7 w-[100%] ">
              <section className="postpage-credits">
                <p className="credits-name">
                  <span>Workspace credits: </span>
                  {postInfo.name}
                </p>
                <p className="credits-handle">
                  <span>Instagram handle: </span>
                  <p
                    className="social-links"
                    dangerouslySetInnerHTML={{ __html: postInfo.handle }}
                  />
                </p>
              </section>

              <section className="postpage-description my-5">
                <p dangerouslySetInnerHTML={{ __html: postInfo.desc }} />
              </section>

              <section className="postpage-links my-5">
                <h3 className="text-lg">Workspace items</h3>
                <ul dangerouslySetInnerHTML={{ __html: postInfo.url }} />
              </section>

              <section className="postpage-disclaimer text-sm pt-5">
                Affiliate Disclaimer:{" "}
                <span className="block">
                  If you purchase a product through our links, we may receive a
                  commission at no additional cost to you.
                </span>
              </section>
            </div>
          </article>

          <div>
            <RelatedPosts postInfo={postInfo} />
          </div>
        </section>
      </main>
    </>
  );
}
