import {
  useEffect,
  useState,
  lazy,
  Suspense,
  useCallback,
  useMemo,
} from "react";
import { useNavigate, useLocation } from "react-router-dom";

const Post = lazy(() => import("./Post"));

export default function SpacesSection() {
  const [posts, setPosts] = useState([]);
  const [filteredPosts, setFilteredPosts] = useState([]);
  const [categories, setCategories] = useState(["All Spaces"]);
  const [loading, setLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);

  const postsPerPage = 24;
  const apiUrl = process.env.REACT_APP_API_URL;

  const navigate = useNavigate();
  const location = useLocation();

  // Memoize category from URL parameters to avoid recalculations
  const category = useMemo(() => {
    const urlParams = new URLSearchParams(location.search);
    return urlParams.get("category") || "All Spaces";
  }, [location.search]);

  const handleNavigation = useCallback(
    (cat, page) => {
      navigate(`?category=${encodeURIComponent(cat)}&page=${page}`);
    },
    [navigate]
  );

  useEffect(() => {
    const fetchPosts = async () => {
      setLoading(true);
      try {
        const response = await fetch(`${apiUrl}/submit/admin/upload`, {
          credentials: "include",
        });
        const data = await response.json();

        setPosts(data);
        setFilteredPosts(data);

        // Remove unwanted categories once to avoid recalculating
        const uniqueCategories = [
          ...new Set(
            data
              .map((post) => post.category)
              .filter((cat) => cat && cat !== "uncategorized")
          ),
        ];
        setCategories(["All Spaces", ...uniqueCategories]);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching posts:", error);
        setLoading(false);
      }
    };

    fetchPosts();
  }, [apiUrl]);

  // Debounced filtering by category to optimize
  useEffect(() => {
    const filterByCategory = () => {
      const filtered =
        category === "All Spaces"
          ? posts
          : posts.filter((post) => post.category === category);
      setFilteredPosts(filtered);
    };

    // Apply debouncing to avoid running too frequently
    const debounceFilter = setTimeout(filterByCategory, 300);
    return () => clearTimeout(debounceFilter);
  }, [category, posts]);

  const currentPosts = useMemo(() => {
    const start = (currentPage - 1) * postsPerPage;
    const end = start + postsPerPage;
    return filteredPosts.slice(start, end);
  }, [filteredPosts, currentPage, postsPerPage]);

  const changeCategory = (cat) => {
    setCurrentPage(1);
    handleNavigation(cat, 1);
  };

  const handleNextPage = () => {
    const nextPage = currentPage + 1;
    if (nextPage <= Math.ceil(filteredPosts.length / postsPerPage)) {
      setCurrentPage(nextPage);
      handleNavigation(category, nextPage);
    }
  };

  const handlePreviousPage = () => {
    const prevPage = currentPage - 1;
    if (prevPage > 0) {
      setCurrentPage(prevPage);
      handleNavigation(category, prevPage);
    }
  };

  return (
    <section
      id="spaces-section"
      className="flex flex-col items-start mb-14 h-full"
    >
      <div className="spaces-section-filter relative overflow-hidden w-full select-none">
        <div className="filter-btns-wrapper overflow-x-auto flex items-center pb-2 scrollbar-hide w-full mr-15 my-5 scroll-smooth">
          <div className="filter-btns flex gap-4 whitespace-nowrap">
            {categories.map((cat) => (
              <button
                key={cat}
                className={`filter-btn ${category === cat ? "active" : ""}`}
                onClick={() => changeCategory(cat)}
              >
                {cat}
              </button>
            ))}
          </div>
        </div>
      </div>

      <div className="spaces-section-cards">
        {loading ? (
          <div className="loading">
            <div className="loading-spinner"></div>
            <p>Loading posts...</p>
          </div>
        ) : (
          <div className="cards-container">
            {currentPosts.length > 0 ? (
              currentPosts.map((post) => (
                <Suspense fallback={<div>Loading...</div>} key={post.id}>
                  <Post {...post} />
                </Suspense>
              ))
            ) : (
              <p>Kindly reload once again!</p>
            )}
          </div>
        )}
      </div>

      <div className="pagination-btns flex justify-end items-center gap-2.5 mt-5 w-full">
        {currentPage > 1 && (
          <button
            className="cursor-pointer block border-0 rounded-md py-2.5 px-4 min-h-[36px] text-sm bg-gray-200 transition-all duration-300 ease-in-out hover:bg-gray-300 pagination-previous"
            onClick={handlePreviousPage}
          >
            Previous
          </button>
        )}
        {currentPage < Math.ceil(filteredPosts.length / postsPerPage) && (
          <button
            className="cursor-pointer block border-0 rounded-md py-2.5 px-4 min-h-[36px] text-sm bg-gray-200 transition-all duration-300 ease-in-out hover:bg-gray-300 pagination-next"
            onClick={handleNextPage}
          >
            Next
          </button>
        )}
      </div>
    </section>
  );
}
