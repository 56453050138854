import { useEffect } from "react";
import { Helmet } from "react-helmet-async";

import { SpeedInsights } from "@vercel/speed-insights/react";
import HeroSection from "../components/HeroSection";
import SpacesSection from "../components/SpacesSection";

export default function IndexPage() {
  return (
    <>
      <SpeedInsights />
      <Helmet>
        <title>DeskOn - Explore Tagged Workspaces</title>
        <meta
          name="description"
          content="Discover and shop your dream desk setup with DeskOn. Explore tagged workspaces, inspiring designs, and product recommendations."
        />
      </Helmet>
      <div className="main-container">
        <HeroSection />
        <SpacesSection />
      </div>
    </>
  );
}
