import { useEffect, useState } from "react";
import { Link } from "react-router-dom";

export default function RelatedPosts({ postInfo }) {
  const [posts, setPosts] = useState([]); // Initialize as an array
  const apiUrl = process.env.REACT_APP_API_URL;

  useEffect(() => {
    const fetchPosts = async () => {
      try {
        const response = await fetch(`${apiUrl}/submit/admin/upload`, {
          credentials: "include",
        });
        const data = await response.json();
        setPosts(data);
      } catch (error) {
        console.error("Error fetching posts:", error);
      }
    };

    fetchPosts();
  }, [apiUrl]);

  // Filter related posts
  const relatedPosts = posts
    .filter((post) => post._id !== postInfo._id)
    .sort(() => Math.random() - 0.5)
    .slice(0, 3);

  return (
    <div>
      {relatedPosts.length > 0 && (
        <div className="mt-10 max-w-[660px] w-full">
          <h3 className="text-2xl font-semibold mb-5">
            More workspaces to explore
          </h3>
          <div className="flex flex-col gap-5 md:flex-row">
            {relatedPosts.map((post) => (
              <Link
                to={`/post/${post._id}`}
                key={post._id}
                className="related-post flex flex-row gap-2 md:flex-col md:gap-1 w-fit"
              >
                <div className="overflow-hidden w-42 rounded-lg">
                  <img
                    className="related-post-img aspect-square object-cover transition-all w-[11rem] md:w-[13rem]"
                    src={post.cover}
                    alt={`${post.name}'s workspace`}
                  />
                </div>
                <div className="flex flex-col gap-2 md:gap-1">
                  <span>#{post.serial}</span>
                  <div className="">
                    <p className="leading-5 text-lg font-semibold">
                      {post.name}'s
                    </p>
                    <span className="leading-5">{post.category}</span>
                  </div>
                </div>
              </Link>
            ))}
          </div>
        </div>
      )}
    </div>
  );
}
